import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchInvitedMembersOfBoardActive } from "modules/boards/boards.action";
import { useRef } from "react";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { GoCheck } from "react-icons/go";
import {
  addUserToTicket,
  deleteUsersFromTicket,
} from "modules/tickets/tickets.action";
import { BsXLg } from "react-icons/bs";
import { boardViewOnlySelector } from "modules/boards/boards.selectors";
import {
  moveTicketAction,
} from "modules/tickets/tickets.action";
import { getStates } from "modules/columns/columns.selectors";

const TicketStates = (props) => {
  const {
    ticket,
    setOpenMoving,
    boardActiveInvitedMembers,
    fetchInvitedMembersOfBoardActive,
    moveTicketAction,
  } = props;
  const { id, ticketId } = useParams();

  const ref = useRef();

  const onSelectState = (state) => {
    moveTicketAction({
      ticket,
      fromStateId: ticket.state,
      toStateId: state._id,
      addedIndex: 0,
    });

    setOpenMoving(false);
  };

  useOnClickOutside(ref, () => setOpenMoving(false));
  useEffect(() => {
    if (boardActiveInvitedMembers.length === 0) {
      fetchInvitedMembersOfBoardActive(id);
    }
  }, []);

  return (
    <div className="ticket-points">
      <div ref={ref} className="ticket-points-wrapper">
        <div className="ticket-points-header">
          <span>States</span>
          <button
            className="ticket-points-header-close-btn"
            onClick={() => setOpenMoving(false)}
          >
            <BsXLg size={11} />
          </button>
        </div>
        <div className="ticket-points-content">
          <div className="ticket-points-content-point">
            <ul>
              {props.states?.map((state) => (
                <li
                  key={state._id}
                  className="point-item"
                  onClick={onSelectState.bind(null, state)}
                >
                  <span>{state.name}</span>
                  <span
                    className="member_check"
                    style={
                      {
                        display: ticket.state === state._id ? "block" : "none",
                      }
                    }
                  >
                    <GoCheck />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  states: getStates(state),
  ticket: state.tickets.ticket,
  userLoggedIn: state.users.user,
  departmentsUsers: state.departments.departmentsUsers,
  boardActive: state.boards.boardActive,
  boardActiveInvitedMembers: state.boards.boardActiveInvitedMembers,
  isAdmin: state.users.isAdmin,
  ticketsUsers: state.tickets.ticketsUsers,
  boardViewOnly: boardViewOnlySelector(state),
});

const mapDispatchToProps = {
  fetchInvitedMembersOfBoardActive,
  addUserToTicket,
  deleteUsersFromTicket,
  moveTicketAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketStates);
