export const getSortGuestDepartments = (state) =>
  state.boardPositions.guestDepartmentsPositions
    ?.sort((a, b) => a.posIndex - b.posIndex)
    .map((dpt) =>
      state?.boards?.guestUserDepartments?.find(
        (department) => (department?._id || department?.id) === dpt.departmentId
      )
    ) || [];

export const getSortGuestDepartmentsPositions = (state) =>
  state.boardPositions.guestDepartmentsPositions?.sort(
    (a, b) => a.posIndex - b.posIndex
  );

export const getSortGuestBoardPositions = (state, departmentId) =>
  state.boardPositions.guestDepartmentsPositions
    ?.find((dpt) => dpt.departmentId === departmentId)
    .boards.sort((a, b) => a.posIndex - b.posIndex);

export const getSortGuestBoard = (state, departmentId) =>
  state.boardPositions.guestDepartmentsPositions
    ?.find((dpt) => dpt.departmentId === departmentId)
    ?.boards.sort((a, b) => a.posIndex - b.posIndex)
    .map((board) =>
      state.boards.invitedUserBoards.find((b) => b?._id === board.boardId)
    ) || [];

export const getSortDepartments = (state) =>
  state?.boardPositions?.departmentsPositions
    ?.sort((a, b) => a.posIndex - b.posIndex)
    .map((dpt) =>
      state?.departments?.listDepartments?.find(
        (department) => (department?._id || department?.id) === dpt.departmentId
      )
    ) || [];

export const getSortBoardsByDepartmentId = (departmentId, state) => {
  return state.boardPositions.departmentsPositions
    ?.find((department) => department.departmentId === departmentId)
    ?.boards?.sort((a, b) => a.posIndex - b.posIndex)
    .map((boardPst) =>
      state.departments.departmentsBoards[departmentId]?.find(
        (board) => (board?._id || board?.id) === boardPst.boardId
      )
    );
};

export const getSortDepartmentsPositions = (state) =>
  state?.boardPositions?.departmentsPositions?.sort(
    (a, b) => a.posIndex - b.posIndex
  );

export const getSortBoardsPositionOfDepartment = (state, departmentId) => {
  return state.boardPositions.departmentsPositions
    ?.find((department) => department.departmentId === departmentId)
    .boards?.sort((a, b) => a.posIndex - b.posIndex);
};
