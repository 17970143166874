const Types = {
  GET_DETAIL_BOARD_SUCCESS: "GET_DETAIL_BOARD_SUCCESS",

  GET_BOARDS_BY_INVITED_USER_SUCCESS: "GET_BOARDS_BY_INVITED_USER_SUCCESS",
  GET_INVITED_MEMBERS_BOARD_ACTIVE_SUCCESS:
    "GET_INVITED_MEMBERS_BOARD_ACTIVE_SUCCESS",

  ADD_INVITED_MEMBERS_BOARD_ACTIVE_SUCCESS:
    "ADD_INVITED_MEMBERS_BOARD_ACTIVE_SUCCESS",
  ADD_INVITED_MEMBERS_BOARD_ACTIVE_PENDING:
    "ADD_INVITED_MEMBERS_BOARD_ACTIVE_PENDING",
  DELETE_INVITED_MEMBERS_BOARD_ACTIVE_SUCCESS:
    "DELETE_INVITED_MEMBERS_BOARD_ACTIVE_SUCCESS",
  FILTER_MEMBER_IN_BOARD_SUCCESS: "FILTER_MEMBER_IN_BOARD_SUCCESS",
  FILTER_ALL_MEMBER_IN_BOARD_SUCCESS: "FILTER_ALL_MEMBER_IN_BOARD_SUCCESS",
  REMOVE_FILTER_ALL_MEMBER_IN_BOARD_SUCCESS:
    "REMOVE_FILTER_ALL_MEMBER_IN_BOARD_SUCCESS",
  REMOVE_FILTER_MEMBER_IN_BOARD_SUCCESS:
    "REMOVE_FILTER_MEMBER_IN_BOARD_SUCCESS",
  FILTER_LABEL_IN_BOARD_SUCCESS: "FILTER_LABEL_IN_BOARD_SUCCESS",
  REMOVE_FILTER_LABEL_IN_BOARD_SUCCESS: "REMOVE_FILTER_LABEL_IN_BOARD_SUCCESS",
  FILTER_EPIC_IN_BOARD_SUCCESS: "FILTER_EPIC_IN_BOARD_SUCCESS",
  REMOVE_FILTER_EPIC_IN_BOARD_SUCCESS: "REMOVE_FILTER_EPIC_IN_BOARD_SUCCESS",
  FILTER_TICKET_IN_BOARD_BY_TITLE_SUCCESS:
    "FILTER_TICKET_IN_BOARD_BY_TITLE_SUCCESS",
  FILTER_NO_MEMBER_IN_TICKET_SUCCESS: "FILTER_NO_MEMBER_IN_TICKET_SUCCESS",
  FILTER_NO_LABEL_IN_TICKET_SUCCESS: "FILTER_NO_LABEL_IN_TICKET_SUCCESS",
  FILTER_NO_EPIC_IN_TICKET_SUCCESS: "FILTER_NO_EPIC_IN_TICKET_SUCCESS",
  FILTER_ALL_LABEL_IN_BOARD_SUCCESS: "FILTER_ALL_LABEL_IN_BOARD_SUCCESS",
  REMOVE_FILTER_ALL_LABEL_IN_BOARD_SUCCESS:
    "REMOVE_FILTER_ALL_LABEL_IN_BOARD_SUCCESS",
  FILTER_ALL_EPIC_IN_BOARD_SUCCESS: "FILTER_ALL_EPIC_IN_BOARD_SUCCESS",
  REMOVE_FILTER_ALL_EPIC_IN_BOARD_SUCCESS:
    "REMOVE_FILTER_ALL_EPIC_IN_BOARD_SUCCESS",
  CLEAR_FILTER_IN_BOARD_SUCCESS: "CLEAR_FILTER_IN_BOARD_SUCCESS",
};
export default Types;
