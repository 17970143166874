const Types = {
  ADD_USER_TO_BOARD: "ADD_USER_TO_BOARD",
  REMOVE_USER_OUT_OF_BOARD: "REMOVE_USER_OUT_OF_BOARD",
  CREATE_NEW_TICKET: "CREATE_NEW_TICKET",
  UPDATE_TICKET_DETAILS: "UPDATE_TICKET_DETAILS",
  ARCHIVE_TICKET: "ARCHIVE_TICKET",
  RESTORE_TICKET: "RESTORE_TICKET",
  MOVE_TICKET: "MOVE_TICKET",
  ADD_STATE: "ADD_STATE",
  UPDATE_STATE_TITLE: "UPDATE_STATE_TITLE",
  MAKE_DONE_STATE: "MAKE_DONE_STATE",
  MOVE_STATE_POSITION: "MOVE_STATE_POSITION",
  ARCHIVE_STATE: "ARCHIVE_STATE",
  RESTORE_STATE: "RESTORE_STATE",
  ADD_USER_TO_TICKET: "ADD_USER_TO_TICKET",
  REMOVE_USER_OUT_OF_TICKET: "REMOVE_USER_OUT_OF_TICKET",
  USER_CREATE_LABEL_TO_BOARD: "USER_CREATE_LABEL_TO_BOARD",
  USER_UPDATE_LABEL_INFO: "USER_UPDATE_LABEL_INFO",
  USER_DELETE_LABEL_OF_BOARD: "USER_DELETE_LABEL_OF_BOARD",
  USER_ADD_LABEL_TO_TICKET: "USER_ADD_LABEL_TO_TICKET",
  USER_REMOVE_LABEL_OF_TICKET: "USER_REMOVE_LABEL_OF_TICKET",
  USER_ADD_FILE_TO_TICKET: "USER_ADD_FILE_TO_TICKET",
  USER_UPDATE_FILE_IN_TICKET: "USER_UPDATE_FILE_IN_TICKET",
  USER_DELETE_FILE_FROM_TICKET: "USER_DELETE_FILE_FROM_TICKET",
  USER_ADD_TASK_TO_TICKET: "USER_ADD_TASK_TO_TICKET",
  USER_UPDATE_TASK: "USER_UPDATE_TASK",
  USER_DELETE_TASK: "USER_DELETE_TASK",
  ADD_EPIC_TO_BOARD: "ADD_EPIC_TO_BOARD",
  UPDATE_EPIC_IN_BOARD: "UPDATE_EPIC_IN_BOARD",
  DELETE_EPIC_FROM_BOARD: "DELETE_EPIC_FROM_BOARD",
};
export default Types;
