const Types = {
  GET_FILES_BY_TICKET_SUCCESS: "GET_FILES_BY_TICKET_SUCCESS",
  UPLOAD_FILES_PENDING: "UPLOAD_FILES_PENDING",
  UPLOAD_FILES_SUCCESS: "UPLOAD_FILES_SUCCESS",

  UPDATE_FILE_PENDING: "UPDATE_FILE_PENDING",
  DELETE_FILE_SUCCESS: "DELETE_FILE_SUCCESS",
  HANDLE_UPLOAD_FILES_FAILED: "HANDLE_UPLOAD_FILES_FAILED",
  GET_ALL_FILES_BY_TICKET_SUCCESS: "GET_ALL_FILES_BY_TICKET_SUCCESS",
};
export default Types;
