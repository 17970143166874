import Types from "./boards-positions.constant";

const initialState = {
  departmentsPositions:
    JSON.parse(localStorage.getItem("departmentsPositions")) || [],
  guestDepartmentsPositions:
    JSON.parse(localStorage.getItem("guestDepartmentsPositions")) || [],
};

const boardPositionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_INIT_DEPARTMENT_POSITION:
      return {
        ...state,
        departmentsPositions: action.payload.departmentsPositions,
      };

    case Types.ADD_DEPARTMENT_POSITION_PENDING:
      return {
        ...state,
        departmentsPositions: action.payload.newDepartmentPositions,
      };

    case Types.ADD_DEPARTMENT_POSITION_SUCCESS:
      return {
        ...state,
        departmentsPositions: state.departmentsPositions.map((dpt) =>
          dpt.departmentId === action.payload.id
            ? { ...dpt, departmentId: action.payload._id }
            : dpt
        ),
      };

    case Types.ARCHIVE_POSITION_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departmentsPositions: state.departmentsPositions.filter(
          (dpt) => dpt.departmentId !== action.payload.department._id
        ),
      };

    case Types.SET_INIT_BOARD_POSITION_TO_DEPARTMENT:
      return {
        ...state,
        departmentsPositions: state.departmentsPositions.map((dpt) => {
          if (dpt.departmentId === action.payload.departmentId) {
            return { ...dpt, boards: action.payload.boardPositions };
          }
          return dpt;
        }),
      };

    case Types.ADD_BOARD_POSITION_TO_DEPARTMENT_PENDING:
      return {
        ...state,
        departmentsPositions: state.departmentsPositions.map((dpt) => {
          if (dpt.departmentId === action.payload.departmentId) {
            return {
              ...dpt,
              boards: [
                ...dpt.boards,
                {
                  boardId: action.payload.newBoard.id,
                  posIndex:
                    (dpt.boards.sort((a, b) => a.posIndex - b.posIndex)[
                      dpt.boards.length - 1
                    ]?.posIndex || 0) + 1000,
                },
              ],
            };
          }
          return dpt;
        }),
      };

    case Types.ADD_BOARD_POSITION_TO_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departmentsPositions: state.departmentsPositions.map((dpt) => {
          if (dpt.departmentId === action.payload.departmentId) {
            return {
              ...dpt,
              boards: dpt.boards.map((board) =>
                board.boardId === action.payload.newBoardAPI.id
                  ? {
                      ...board,
                      boardId: action.payload.newBoardAPI?._id,
                    }
                  : board
              ),
            };
          }
          return dpt;
        }),
      };

    case Types.ARCHIVE_POSITION_BOARD_SUCCESS:
      return {
        ...state,
        departmentsPositions: state.departmentsPositions.map((dpt) => {
          if (dpt.departmentId === action.payload.board.department) {
            return {
              ...dpt,
              boards: dpt.boards.filter(
                (board) => board.boardId !== action.payload.board._id
              ),
            };
          }
          return dpt;
        }),
      };

    case Types.UPDATE_DEPARTMENTS_BOARD_POSITION:
      return {
        ...state,
        departmentsPositions: action.payload.newDepartmentsPosition,
      };

    case Types.INIT_GUEST_BOARD_DEPARTMENT_POSITION:
      return {
        ...state,
        guestDepartmentsPositions: action.payload.guestDepartmentsPositions,
      };

    case Types.UPDATE_GUEST_BOARD_DEPARTMENT_POSITION:
      return {
        ...state,
        guestDepartmentsPositions: action.payload.newGuestDepartmentsPositions,
      };

    default:
      return state;
  }
};
export default boardPositionsReducer;
