const Types = {
  CREATE_GITHUB_DATA_BY_BOARD: "CREATE_GITHUB_DATA_BY_BOARD",
  GET_GITHUB_DATA_BY_BOARD: "GET_GITHUB_DATAS_BY_BOARD",
  UPDATE_GITHUB_DATA_PENDING: "UPDATE_GITHUB_DATA_PENDING",
  UPDATE_GITHUB_DATA_SUCCESS: "UPDATE_GITHUB_DATA_SUCCESS",
  GET_PULL_REQUESTS_BY_TICKET: "GET_PULL_REQUESTS_BY_TICKET",
  FETCH_PULL_REQUESTS_BY_TICKET_PENDING:
    "FETCH_PULL_REQUESTS_BY_TICKET_PENDING",
  FETCH_PULL_REQUESTS_BY_TICKET_FAILED: "FETCH_PULL_REQUESTS_BY_TICKET_FAILED",
};
export default Types;
