import React from 'react'

const NotFound = () => {
  return (
    <h4 className='notfound'>
      PAGE NOT FOUND
    </h4>
  )
}

export default NotFound