import { apiBoard, apiUser } from "api";
import {
  getAllUsersByTicketSuccess,
  getTicketsByStateSucess,
} from "modules/tickets/tickets.action";
import { getAllLabelsByTicketSuccess } from "modules/labels/labels.action";
import { getAllFilesByTicketSuccess } from "modules/files/files.action";

import { toastError } from "utils/toastHelper";
import Types from "./boards.constant";
import { getAllTasksByTicketSuccess } from "modules/ticketTasks/tasks.action";
import { setInitGuestBoardDepartmentPositions } from "modules/departmentBoardPositions/boards-positions.action";

const getDetailBoardSuccess = (board) => ({
  type: Types.GET_DETAIL_BOARD_SUCCESS,
  payload: board,
});

const getDetailBoardById = (id) => async (dispatch) => {
  try {
    const res = await apiBoard.getDetailBoardById(id);
    const data = res.data;
    dispatch(getDetailBoardSuccess(data));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getBoardsByInvitedUserSuccess = (
  invitedUserBoards,
  guestUserDepartments
) => ({
  type: Types.GET_BOARDS_BY_INVITED_USER_SUCCESS,
  payload: {
    invitedUserBoards: invitedUserBoards,
    guestUserDepartments: guestUserDepartments,
  },
});

const getInvitedMembersOfBoardActiveSuccess = (users) => ({
  type: Types.GET_INVITED_MEMBERS_BOARD_ACTIVE_SUCCESS,
  payload: users,
});

const fetchBoardsByInvitedUser = (userId) => async (dispatch, getState) => {
  try {
    const guestDepartmentsPositions =
      getState().boardPositions.guestDepartmentsPositions;
    const res = await apiUser.getBoardsByInvitedMember(userId).then((res) => {
      const boards = res.data.boards.map((board) => board.board);
      dispatch(
        getBoardsByInvitedUserSuccess(boards, res.data.guestDepartments)
      );
      dispatch(
        setInitGuestBoardDepartmentPositions(
          guestDepartmentsPositions,
          boards,
          res.data.guestDepartments
        )
      );
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchInvitedMembersOfBoardActive = (boardId) => async (dispatch) => {
  try {
    const res = await apiBoard.getInvitedMembersOfBoard(boardId);
    dispatch(
      getInvitedMembersOfBoardActiveSuccess(
        res.data.invitedMembers.map((user) => user.user)
      )
    );
    return Promise.resolve(res.data.invitedMembers);
  } catch (error) {
    return Promise.reject(error);
  }
};
//
const addInvitedMembersToBoardActivePending = (member) => {
  return {
    type: Types.ADD_INVITED_MEMBERS_BOARD_ACTIVE_PENDING,
    payload: member,
  };
};
const addInvitedMembersToBoardActiveSuccess = () => {
  return {
    type: Types.ADD_INVITED_MEMBERS_BOARD_ACTIVE_SUCCESS,
  };
};
const addInvitedMembersToBoardActive = (boardId, user) => async (dispatch) => {
  try {
    dispatch(addInvitedMembersToBoardActivePending(user));
    const member = {
      users: [user._id],
    };
    const res = await apiBoard
      .addInvitedMembersToBoard(boardId, member)
      .then(() => dispatch(addInvitedMembersToBoardActiveSuccess()));

    return Promise.resolve(res);
  } catch (error) {
    toastError(error.response.data);

    return Promise.reject(error);
  }
};
const deleteInvitedMembersFromBoardSuccess = (userId) => ({
  type: Types.DELETE_INVITED_MEMBERS_BOARD_ACTIVE_SUCCESS,
  payload: userId,
});
const deleteInvitedMembersFromBoard = (boardId, user) => async (dispatch) => {
  try {
    dispatch(deleteInvitedMembersFromBoardSuccess(user._id));
    const member = {
      users: [user._id],
    };
    const res = await apiBoard.deleteInvitedMembersFromBoard(boardId, member);
    return Promise.resolve(res);
  } catch (error) {
    toastError(error.response.data);
    return Promise.reject(error);
  }
};

const fetchTicketsByBoard = (boardId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const res = await apiBoard.getTicketsOfBoard(boardId).then((res) => {
      const states = state.states.states.reduce((obj, item) => {
        return {
          ...obj,
          [item["_id"]]: [],
        };
      }, {});
      const ticketTasks = {};
      const ticketUsers = {};
      const ticketAttachments = {};
      const ticketLabels = [];

      res.data.forEach((ticket) => {
        states[ticket.state].push(ticket);
        ticketTasks[ticket._id] = ticket.tasks;
        ticketUsers[ticket._id] = ticket.members;
        ticketAttachments[ticket._id] = ticket.attachments
          .map((file) => ({
            ...file?._doc,
            isCovered: file?.isCovered,
          }))
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        ticketLabels.push({
          ticketId: ticket._id,
          labelsActive: ticket.labels,
        });
      });
      dispatch(getTicketsByStateSucess(states));
      dispatch(getAllLabelsByTicketSuccess(ticketLabels));
      dispatch(getAllUsersByTicketSuccess(ticketUsers));
      dispatch(getAllFilesByTicketSuccess(ticketAttachments));
      dispatch(getAllTasksByTicketSuccess(ticketTasks));
    });
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getDetailBoardById,
  fetchBoardsByInvitedUser,
  fetchInvitedMembersOfBoardActive,
  addInvitedMembersToBoardActive,
  deleteInvitedMembersFromBoard,
  fetchTicketsByBoard,
  addInvitedMembersToBoardActivePending,
  deleteInvitedMembersFromBoardSuccess,
};

export * from "./boardsFilter.action";
