const Types = {
  LOGIN_PENDING: "LOGIN_PENDING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_ERROR: "GET_USERS_ERROR",
  GET_ME_SUCCESS: "GET_ME_SUCCESS",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
};

export default Types;
