import { apiDepartment, apiUser } from "api";
import { boardColors } from "utils/colors";
import { toastError } from "utils/toastHelper";
import Types from "./departments.constant";
import {
  addBoardPositionToDepartmentPending,
  addBoardPositionToDepartmentSuccess,
  addDepartmentPositionPending,
  addDepartmentPositionSuccess,
  archivedPositionBoardSuccess,
  archivedPositionDepartmentSuccess,
  setInitBoardsPositionToDepartment,
  setInitDepartmentPositions,
} from "modules/departmentBoardPositions/boards-positions.action";
import {
  getSortBoardsPositionOfDepartment,
  getSortDepartmentsPositions,
} from "modules/departmentBoardPositions/boards-positions.selector";
//SYNC

const getDepartmentSuccess = (departments) => {
  return {
    type: Types.GET_DEPARTMENT_SUCCESS,
    payload: {
      departments: departments,
    },
  };
};

const getSelectedDepartmentSuccess = (department) => {
  return {
    type: Types.GET_SELECTED_DEPARTMENT_SUCCESS,
    payload: { department },
  };
};

const getSelectedDepartmenPending = () => {
  return {
    type: Types.GET_SELECTED_DEPARTMENT_PENDING,
  };
};

const getDepartmentPending = () => {
  return {
    type: Types.GET_DEPARTMENT_PENDING,
  };
};

const getDepartmentError = (error) => {
  return {
    type: Types.GET_DEPARTMENT_ERROR,
    payload: error,
  };
};

const addDepartmentSuccess = (data) => {
  return {
    type: Types.ADD_DEPARTMENT_SUCCESS,
    payload: data,
  };
};

const addDepartmentPending = (newDepartment) => {
  return {
    type: Types.ADD_DEPARTMENT_PENDING,
    payload: {
      newDepartment: newDepartment,
    },
  };
};

const updateDepartmentSuccess = (currentDepartment) => {
  return {
    type: Types.UPDATE_DEPARTMENT_SUCCESS,
    payload: currentDepartment,
  };
};

const getUserInDepartmentSuccess = (departmentId, users) => ({
  type: Types.GET_USERS_IN_DEPARTMENT_SUCCESS,
  payload: {
    departmentId: departmentId,
    users: users,
  },
});

const addUsersToDepartmentSuccess = (departmentId, user) => ({
  type: Types.ADD_USERS_TO_DEPARTMENT_SUCCESS,
  payload: {
    departmentId: departmentId,
    user: user,
  },
});

const deleteUsersFromDepartmentSuccess = (departmentId, user) => ({
  type: Types.DELETE_USERS_FROM_DEPARTMENT_SUCCESS,
  payload: {
    departmentId: departmentId,
    user: user,
  },
});

const getBoardByDepartmentSuccess = (departmentId, boards) => ({
  type: Types.GET_BOARD_BY_DEPARTMENT_SUCCESS,
  payload: {
    departmentId: departmentId,
    boards: boards,
  },
});

const addBoardToDepartmentPending = (departmentId, newBoard) => ({
  type: Types.ADD_BOARD_TO_DEPARTMENT_PENDING,
  payload: { departmentId: departmentId, newBoard: newBoard },
});

const addBoardToDepartmentSuccess = (departmentId, newBoardAPI) => ({
  type: Types.ADD_BOARD_TO_DEPARTMENT_SUCCESS,
  payload: { departmentId: departmentId, newBoardAPI: newBoardAPI },
});

const updateBoardPending = (departmentId, currentBoard) => ({
  type: Types.UPDATE_BOARD_PENDING,
  payload: { departmentId: departmentId, currentBoard: currentBoard },
});

const updateBoardSuccess = () => ({
  type: Types.UPDATE_BOARD_SUCCESS,
});

//ASYNC

const addDepartment = () => async (dispatch, getState) => {
  try {
    const departmentsPositions = getSortDepartmentsPositions(getState());
    const positionIndex =
      (departmentsPositions[departmentsPositions.length - 1]?.posIndex || 0) +
      1000;
    const newDepartment = {
      id: Date.now(),
      name: "New Department " + Date.now(),
      description: "New Department",
      positionIndex: positionIndex,
    };
    const newDepartmentPositions = [
      ...departmentsPositions,
      {
        departmentId: newDepartment.id,
        posIndex:
          (departmentsPositions[departmentsPositions.length - 1]?.posIndex ||
            0) + 1000,
        boards: [],
      },
    ];
    dispatch(addDepartmentPending(newDepartment));
    dispatch(addDepartmentPositionPending(newDepartmentPositions));

    const res = await apiDepartment.addDepartment(newDepartment).then((res) => {
      dispatch(addDepartmentSuccess({ ...newDepartment, ...res.data }));
      dispatch(addDepartmentPositionSuccess({ ...newDepartment, ...res.data }));
    });
    return Promise.resolve(res);
  } catch (error) {
    toastError(error.response.data);
    return Promise.reject(error);
  }
};

const updateDepartment = (currentDepartment) => async (dispatch) => {
  try {
    dispatch(updateDepartmentSuccess(currentDepartment));
    const res = await apiDepartment.updateDepartment(
      currentDepartment._id,
      currentDepartment
    );
    return Promise.resolve(res);
  } catch (error) {
    toastError(error.response.data);
    return Promise.reject(error);
  }
};

const fetchDepartments = () => async (dispatch, getState) => {
  const departmentsPositions = getState().boardPositions.departmentsPositions;
  return await apiDepartment
    .getDepartments()
    .then((res) => {
      dispatch(getDepartmentSuccess(res.data));
      dispatch(setInitDepartmentPositions(departmentsPositions, res.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getDepartmentError(err));
    });
};

const fetchUsersDepartment = (departmentId) => async (dispatch) => {
  try {
    const res = await apiDepartment.getUsersDepartment(departmentId);
    dispatch(
      getUserInDepartmentSuccess(departmentId, [
        ...res.data.users.map((user) => user.userId),
      ])
    );
    return Promise.resolve(res.data.users);
  } catch (error) {
    return Promise.reject(error);
  }
};

const addUsersToDepartment =
  (departmentId, users = []) =>
  async (dispatch) => {
    try {
      users.forEach((u) => {
        dispatch(addUsersToDepartmentSuccess(departmentId, u));
      });

      const res = await apiDepartment.addUsersToDepartment(departmentId, {
        users: users.map((u) => u._id),
      });

      return Promise.resolve(res);
    } catch (error) {
      toastError(error.response.data);
      return Promise.reject(error);
    }
  };

const deleteUsersFromDepartment =
  (departmentId, users = []) =>
  async (dispatch) => {
    try {
      users.forEach((user) => {
        dispatch(deleteUsersFromDepartmentSuccess(departmentId, user));
      });

      const res = await apiDepartment.deleteUsersFromDepartment(departmentId, {
        users: users.map((user) => user._id),
      });

      return Promise.resolve(res);
    } catch (error) {
      toastError(error.response.data);
      return Promise.reject(error);
    }
  };

const fetchBoardsByDepartment =
  (departmentId) => async (dispatch, getState) => {
    const departmentsPositions = getState().boardPositions.departmentsPositions;
    dispatch(getSelectedDepartmenPending());
    await apiDepartment
      .getBoardsDepartment(departmentId)
      .then((res) => {
        dispatch(getBoardByDepartmentSuccess(departmentId, res.data.boards));
        dispatch(
          setInitBoardsPositionToDepartment(
            departmentsPositions,
            departmentId,
            res.data.boards
          )
        );
        dispatch(getSelectedDepartmentSuccess(res.data.department));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getBoardByDepartmentSuccess(departmentId, []));
      });
  };

const addBoardToDepartment = (departmentId) => async (dispatch, getState) => {
  try {
    const boardsPositionsOfDepartment = getSortBoardsPositionOfDepartment(
      getState(),
      departmentId
    );
    const newPositionIndex =
      boardsPositionsOfDepartment[boardsPositionsOfDepartment.length - 1]
        ?.posIndex || 0 + 1000;
    const newBoard = {
      id: Date.now(),
      content: {
        name: "New Board" + Date.now(),
        description:
          boardColors[Math.floor(Math.random() * boardColors.length)],
        department: departmentId,
        positionIndex: newPositionIndex,
      },
    };
    dispatch(addBoardToDepartmentPending(departmentId, newBoard));
    dispatch(addBoardPositionToDepartmentPending(departmentId, newBoard));

    const res = await apiDepartment.addBoard(newBoard.content).then((res) => {
      dispatch(
        addBoardToDepartmentSuccess(departmentId, { ...newBoard, ...res.data })
      );
      dispatch(
        addBoardPositionToDepartmentSuccess(departmentId, {
          ...newBoard,
          ...res.data,
        })
      );
    });
    return Promise.resolve(res);
  } catch (error) {
    toastError(error);
    return Promise.reject(error);
  }
};

const updateBoard = (departmentId, currentBoard) => async (dispatch) => {
  try {
    dispatch(updateBoardPending(departmentId, currentBoard));
    const res = await apiDepartment
      .updateBoard(currentBoard._id, currentBoard.content)
      .then(() => {
        dispatch(updateBoardSuccess());
      });
    return Promise.resolve(res);
  } catch (error) {
    toastError(error.response.data);

    return Promise.reject(error);
  }
};

const getDepartmentsByCurrentUserSuccess = (departments) => ({
  type: Types.GET_DEPARTMENTS_BY_CURRENT_USER_SUCCESS,
  payload: {
    listDepartments: departments,
  },
});
const fetchDepartmentsByCurrentUser =
  (userId) => async (dispatch, getState) => {
    try {
      const departmentsPositions =
        getState().boardPositions.departmentsPositions;
      const res = await apiUser
        .getDepartmentsByCurrentUser(userId)
        .then((res) => {
          const data = res.data.departments.map(
            (department) => department.departmentId
          );
          dispatch(getDepartmentsByCurrentUserSuccess(data));
          dispatch(setInitDepartmentPositions(departmentsPositions, data));
        });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  };
//
const archiveBoardSuccess = (board) => {
  return {
    type: Types.ARCHIVE_BOARD_SUCCESS,
    payload: { board: board },
  };
};
const archiveBoard = (board) => async (dispatch) => {
  try {
    dispatch(archiveBoardSuccess(board));
    dispatch(archivedPositionBoardSuccess(board));
    const res = await apiDepartment.updateBoard(board._id, {
      isArchived: true,
    });
    return Promise.resolve(res);
  } catch (error) {
    toastError(error.response.data);
    return Promise.reject(error);
  }
};

const archiveDepartmentSuccess = (department) => {
  return {
    type: Types.ARCHIVE_DEPARTMENT_SUCCESS,
    payload: { department: department },
  };
};
const archiveDepartment = (department) => async (dispatch, getState) => {
  try {
    dispatch(archiveDepartmentSuccess(department));
    dispatch(archivedPositionDepartmentSuccess(department));

    const res = await apiDepartment.updateDepartment(department._id, {
      isArchived: true,
    });
    return Promise.resolve(res);
  } catch (error) {
    toastError(error.response.data);
    return Promise.reject(error);
  }
};

const updateDepartmentIdOfBoard = (
  newBoard,
  fromDepartmentId,
  toDepartmentId
) => ({
  type: Types.UPDATE_DEPARTMENT_ID_OF_BOARD,
  payload: {
    newBoard: newBoard,
    fromDepartmentId: fromDepartmentId,
    toDepartmentId: toDepartmentId,
  },
});
export {
  getSelectedDepartmenPending,
  getDepartmentSuccess,
  getDepartmentPending,
  getDepartmentError,
  addDepartmentSuccess,
  addDepartmentPending,
  fetchDepartments,
  addDepartment,
  updateDepartmentSuccess,
  updateDepartment,
  fetchUsersDepartment,
  addUsersToDepartment,
  deleteUsersFromDepartment,
  fetchBoardsByDepartment,
  addBoardToDepartment,
  updateBoard,
  fetchDepartmentsByCurrentUser,
  archiveBoardSuccess,
  archiveBoard,
  archiveDepartment,
  updateDepartmentIdOfBoard,
};
