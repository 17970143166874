const Types = {
  GET_DEPARTMENT_SUCCESS: "GET_DEPARTMENT_SUCCESS",
  GET_DEPARTMENT_PENDING: "GET_DEPARTMENT_PENDING",
  GET_DEPARTMENT_ERROR: "GET_DEPARTMENT_ERROR",
  ADD_DEPARTMENT_SUCCESS: "ADD_DEPARTMENT_SUCCESS",
  ADD_DEPARTMENT_PENDING: "ADD_DEPARTMENT_PENDING",
  DELETE_DEPARTMENT_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
  ARCHIVE_DEPARTMENT_SUCCESS: "ARCHIVE_DEPARTMENT_SUCCESS",
  UPDATE_DEPARTMENT_SUCCESS: "UPDATE_DEPARTMENT_SUCCESS",
  GET_USERS_IN_DEPARTMENT_SUCCESS: "GET_USERS_IN_DEPARTMENT_SUCCESS",
  ADD_USERS_TO_DEPARTMENT_SUCCESS: "ADD_USERS_TO_DEPARTMENT_SUCCESS",
  DELETE_USERS_FROM_DEPARTMENT_SUCCESS: "DELETE_USERS_FROM_DEPARTMENT_SUCCESS",

  GET_BOARD_BY_DEPARTMENT_SUCCESS: "GET_BOARD_BY_DEPARTMENT_SUCCESS",

  ADD_BOARD_TO_DEPARTMENT_PENDING: "ADD_BOARD_TO_DEPARTMENT_PENDING",
  ADD_BOARD_TO_DEPARTMENT_SUCCESS: "ADD_BOARD_TO_DEPARTMENT_SUCCESS",

  UPDATE_BOARD_PENDING: "UPDATE_BOARD_PENDING",
  UPDATE_BOARD_SUCCESS: "UPDATE_BOARD_SUCCESS",
  UPDATE_DEPARTMENT_ID_OF_BOARD: "UPDATE_DEPARTMENT_ID_OF_BOARD",

  DELETE_BOARD_FROM_DEPARTMENT_SUCCESS: "DELETE_BOARD_FROM_DEPARTMENT_SUCCESS",
  GET_DEPARTMENTS_BY_CURRENT_USER_SUCCESS:
    "GET_DEPARTMENTS_BY_CURRENT_USER_SUCCESS",
  ARCHIVE_BOARD_SUCCESS: "ARCHIVE_BOARD_SUCCESS",
  GET_SELECTED_DEPARTMENT_SUCCESS: "GET_SELECTED_DEPARTMENT_SUCCESS",
  GET_SELECTED_DEPARTMENT_PENDING: "GET_SELECTED_DEPARTMENT_PENDING",
};
export default Types;
