const Types = {
  SET_INIT_DEPARTMENT_POSITION: "SET_INIT_DEPARTMENT_POSITION",
  SET_INIT_BOARD_POSITION_TO_DEPARTMENT:
    "SET_INIT_BOARD_POSITION_TO_DEPARTMENT",

  ADD_DEPARTMENT_POSITION_PENDING: "ADD_DEPARTMENT_POSITION_PENDING",
  ADD_DEPARTMENT_POSITION_SUCCESS: "ADD_DEPARTMENT_POSITION_SUCCESS",
  ARCHIVE_POSITION_DEPARTMENT_SUCCESS: "ARCHIVE_POSITION_DEPARTMENT_SUCCESS",
  ADD_BOARD_POSITION_TO_DEPARTMENT_PENDING:
    "ADD_BOARD_POSITION_TO_DEPARTMENT_PENDING",

  ADD_BOARD_POSITION_TO_DEPARTMENT_SUCCESS:
    "ADD_BOARD_POSITION_TO_DEPARTMENT_SUCCESS",
  ARCHIVE_POSITION_BOARD_SUCCESS: "ARCHIVE_POSITION_BOARD_SUCCESS",

  UPDATE_DEPARTMENTS_BOARD_POSITION: "UPDATE_DEPARTMENTS_BOARD_POSITION",
  INIT_GUEST_BOARD_DEPARTMENT_POSITION: "INIT_GUEST_BOARD_DEPARTMENT_POSITION",
  UPDATE_GUEST_BOARD_DEPARTMENT_POSITION:
    "UPDATE_GUEST_BOARD_DEPARTMENT_POSITION",
};

export default Types;
