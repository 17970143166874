const departmentsPositionsMiddleWare =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (
      [
        "ADD_DEPARTMENT_POSITION_SUCCESS",
        "ADD_DEPARTMENT_POSITION_PENDING",
        "ADD_BOARD_POSITION_TO_DEPARTMENT_PENDING",
        "ADD_BOARD_POSITION_TO_DEPARTMENT_SUCCESS",
        "ARCHIVE_POSITION_BOARD_SUCCESS",
        "ARCHIVE_POSITION_DEPARTMENT_SUCCESS",
        "UPDATE_DEPARTMENTS_BOARD_POSITION",
        "UPDATE_GUEST_BOARD_DEPARTMENT_POSITION",
      ].includes(action.type)
    ) {
      const newDepartmentsPositions =
        getState().boardPositions.departmentsPositions;
      localStorage.setItem(
        "departmentsPositions",
        JSON.stringify(newDepartmentsPositions)
      );
      if (!getState().users.isAdmin) {
        const newGuestDepartmentsPositions =
          getState().boardPositions.guestDepartmentsPositions;
        localStorage.setItem(
          "guestDepartmentsPositions",
          JSON.stringify(newGuestDepartmentsPositions)
        );
      }
    }
  };
export default departmentsPositionsMiddleWare;
