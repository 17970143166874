import { apiDepartment } from "api";
import { updateDepartmentIdOfBoard } from "modules/departments/departments.action";
import Types from "./boards-positions.constant";
import { getSortBoardsPositionOfDepartment } from "./boards-positions.selector";
import { getSortDepartmentsPositions } from "./boards-positions.selector";
import {
  getSortGuestBoardPositions,
  getSortGuestDepartmentsPositions,
} from "./boards-positions.selector";

const addDepartmentPositionPending = (newDepartmentPositions) => {
  return {
    type: Types.ADD_DEPARTMENT_POSITION_PENDING,
    payload: {
      newDepartmentPositions: newDepartmentPositions,
    },
  };
};

const addDepartmentPositionSuccess = (newDepartment) => {
  return {
    type: Types.ADD_DEPARTMENT_POSITION_SUCCESS,
    payload: newDepartment,
  };
};

const addBoardPositionToDepartmentPending = (departmentId, newBoard) => ({
  type: Types.ADD_BOARD_POSITION_TO_DEPARTMENT_PENDING,
  payload: {
    departmentId: departmentId,
    newBoard: newBoard,
  },
});

const addBoardPositionToDepartmentSuccess = (departmentId, newBoardAPI) => ({
  type: Types.ADD_BOARD_POSITION_TO_DEPARTMENT_SUCCESS,
  payload: {
    departmentId: departmentId,
    newBoardAPI: newBoardAPI,
  },
});

const setInitDepartmentPositions = (departmentsPositions, dataDepartments) => {
  // const departmentsPositions = localStorage.getItem("departmentsPositions");
  return {
    type: Types.SET_INIT_DEPARTMENT_POSITION,
    payload: {
      departmentsPositions: dataDepartments.map((dpt) => {
        const dptPst = (departmentsPositions || [])?.find(
          (dptPst) => dptPst.departmentId === dpt?._id
        );
        if (dptPst) {
          return dptPst;
        } else {
          return {
            departmentId: dpt?._id,
            posIndex:
              // dpt?.positionIndex ||
              Date.parse(dpt?.createdAt) || 0,
            boards: [],
          };
        }
      }),
    },
  };
};

const setInitBoardsPositionToDepartment = (
  departmentsPositions,
  departmentId,
  boardsData
) => {
  // const departmentsPositions = localStorage.getItem("departmentsPositions");
  const dptPst = (departmentsPositions || [])?.find(
    (dptPst) => dptPst.departmentId === departmentId
  );
  return {
    type: Types.SET_INIT_BOARD_POSITION_TO_DEPARTMENT,
    payload: {
      departmentId: departmentId,
      boardPositions: boardsData.map((board) => {
        const boardPst = (dptPst ? dptPst.boards : []).find(
          (b) => b.boardId === board?._id
        );
        if (boardPst) {
          return boardPst;
        } else {
          return {
            boardId: board?._id,
            posIndex:
              // board?.positionIndex ||
              Date.parse(board?.createdAt) || 0,
          };
        }
      }),
    },
  };
};

const updateNewPositionBoardsDepartments = (newDepartmentsPosition) => ({
  type: Types.UPDATE_DEPARTMENTS_BOARD_POSITION,
  payload: {
    newDepartmentsPosition: newDepartmentsPosition,
  },
});

const moveDepartmentAction = (dropResult) => (dispatch, getState) => {
  try {
    const departmentsPositions = getSortDepartmentsPositions(getState());

    const isMoveForward = dropResult.addedIndex - dropResult.removedIndex >= 0;
    const aboveDepartment = isMoveForward
      ? departmentsPositions[dropResult.addedIndex]
      : departmentsPositions[dropResult.addedIndex - 1];
    const belowDepartment = isMoveForward
      ? departmentsPositions[dropResult.addedIndex + 1]
      : departmentsPositions[dropResult.addedIndex];
    let newPosition = 0;
    if (aboveDepartment && belowDepartment) {
      newPosition = (aboveDepartment.posIndex + belowDepartment.posIndex) / 2;
    } else {
      if (isMoveForward) {
        if (!belowDepartment) {
          newPosition = aboveDepartment.posIndex + 1;
        }
      } else {
        if (!aboveDepartment) {
          newPosition = belowDepartment.posIndex - 1;
          console.log(newPosition);
        }
      }
    }
    const newPositionDepartment = {
      ...departmentsPositions[dropResult.removedIndex],
      posIndex: newPosition,
    };
    const newDepartmentsPositions = departmentsPositions.map((dpt) =>
      dpt.departmentId === newPositionDepartment.departmentId
        ? { ...dpt, ...newPositionDepartment }
        : dpt
    );

    dispatch(updateNewPositionBoardsDepartments(newDepartmentsPositions));
  } catch (error) {
    console.log(error);
  }
};

const moveBoardAction = (dropResult) => async (dispatch, getState) => {
  try {
    const { newIndex, oldIndex, boardId, fromDepartmentId, toDepartmentId } =
      dropResult;
    const departmentsPositions = getSortDepartmentsPositions(getState());

    const isMoveForward = newIndex - oldIndex >= 0;
    if (toDepartmentId === fromDepartmentId) {
      const boardsPositionsOfDepartment = getSortBoardsPositionOfDepartment(
        getState(),
        fromDepartmentId
      );
      let newPosition =
        boardsPositionsOfDepartment.find((board) => board.boardId === boardId)
          .posIndex || 0;
      const leftBoard = isMoveForward
        ? boardsPositionsOfDepartment[newIndex]
        : boardsPositionsOfDepartment[newIndex - 1];
      const rightBoard = isMoveForward
        ? boardsPositionsOfDepartment[newIndex + 1]
        : boardsPositionsOfDepartment[newIndex];

      if (leftBoard && rightBoard) {
        newPosition = (leftBoard.posIndex + rightBoard.posIndex) / 2;
      } else {
        if (isMoveForward) {
          if (!rightBoard) {
            newPosition = leftBoard.posIndex + 1;
          }
        } else {
          if (!leftBoard) {
            newPosition = rightBoard.posIndex - 1;
          }
        }
      }
      const newPositionBoardsOfDepartment = boardsPositionsOfDepartment.map(
        (board) =>
          board.boardId === boardId
            ? {
                ...board,
                posIndex: newPosition,
              }
            : board
      );
      const newDepartmentsPosition = departmentsPositions.map((dpt) =>
        dpt.departmentId === toDepartmentId
          ? { ...dpt, boards: newPositionBoardsOfDepartment }
          : dpt
      );

      dispatch(updateNewPositionBoardsDepartments(newDepartmentsPosition));
    } else {
      if (getState().users.user.role !== "ADMIN") {
        return;
      }
      const boardsPositionsOfDepartment = getSortBoardsPositionOfDepartment(
        getState(),
        toDepartmentId
      );
      let newPosition = 0;
      const leftBoard = boardsPositionsOfDepartment[newIndex - 1];
      const rightBoard = boardsPositionsOfDepartment[newIndex];
      if (leftBoard && rightBoard) {
        newPosition = (leftBoard.posIndex + rightBoard.posIndex) / 2;
      } else {
        if (!rightBoard) {
          newPosition = leftBoard ? leftBoard.posIndex + 1 : 1000;
        } else {
          if (!leftBoard) {
            newPosition = (rightBoard?.posIndex || 0) - 1;
          }
        }
      }
      const newDepartmentsPosition = departmentsPositions.map((department) => {
        if (department.departmentId === fromDepartmentId) {
          return {
            ...department,
            boards: department.boards.filter(
              (board) => board.boardId !== boardId
            ),
          };
        } else if (department.departmentId === toDepartmentId) {
          return {
            ...department,
            boards: [
              ...department.boards,
              { boardId: boardId, posIndex: newPosition },
            ],
          };
        }
        return department;
      });

      dispatch(updateNewPositionBoardsDepartments(newDepartmentsPosition));
      const board = getState().departments.departmentsBoards[
        fromDepartmentId
      ]?.find((board) => (board?._id || board?.id) === boardId);
      dispatch(
        updateDepartmentIdOfBoard(
          { ...board, department: toDepartmentId },
          fromDepartmentId,
          toDepartmentId
        )
      );
      await apiDepartment.updateBoard(board._id, {
        department: toDepartmentId,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const setInitGuestBoardDepartmentPositions = (
  guestDepartmentsPositions,
  boards,
  guestDepartments
) => {
  return {
    type: Types.INIT_GUEST_BOARD_DEPARTMENT_POSITION,
    payload: {
      guestDepartmentsPositions: guestDepartments.map((dpt) => {
        const dptPst = (guestDepartmentsPositions || [])?.find(
          (dptPst) => dptPst.departmentId === dpt?._id
        );
        const boardFilter = boards.filter(
          (board) => board.department?._id === dpt?._id
        );
        if (dptPst) {
          return {
            ...dptPst,
            boards: boardFilter.map((board) => {
              const boardPst = dptPst.boards.find(
                (b) => b.boardId === board?._id
              );
              if (boardPst) {
                return boardPst;
              } else {
                return {
                  boardId: board?._id,
                  posIndex:
                    // board?.positionIndex ||
                    Date.parse(board?.createdAt) || 0,
                };
              }
            }),
          };
        } else {
          return {
            departmentId: dpt?._id,
            posIndex:
              //  dpt?._positionIndex ||
              Date.parse(dpt?.createdAt) || 0,
            boards: boardFilter.map((board) => ({
              boardId: board?._id,
              posIndex:
                // board?.positionIndex ||
                Date.parse(board?.createdAt) || 0,
            })),
          };
        }
      }),
    },
  };
};
const moveGuestDepartmentAction = (dropResult) => (dispatch, getState) => {
  try {
    const guestDepartmentsPositions = getSortGuestDepartmentsPositions(
      getState()
    );
    const isMoveForward = dropResult.addedIndex - dropResult.removedIndex >= 0;
    const aboveDepartment = isMoveForward
      ? guestDepartmentsPositions[dropResult.addedIndex]
      : guestDepartmentsPositions[dropResult.addedIndex - 1];
    const belowDepartment = isMoveForward
      ? guestDepartmentsPositions[dropResult.addedIndex + 1]
      : guestDepartmentsPositions[dropResult.addedIndex];
    let newPosition = 0;
    if (aboveDepartment && belowDepartment) {
      newPosition = (aboveDepartment.posIndex + belowDepartment.posIndex) / 2;
    } else {
      if (isMoveForward) {
        if (!belowDepartment) {
          newPosition = aboveDepartment.posIndex + 1;
        }
      } else {
        if (!aboveDepartment) {
          newPosition = belowDepartment.posIndex - 1;
        }
      }
    }
    const newPositionGuestDepartment = {
      ...guestDepartmentsPositions[dropResult.removedIndex],
      posIndex: newPosition,
    };
    const newGuestDepartmentsPositions = guestDepartmentsPositions.map((dpt) =>
      dpt.departmentId === newPositionGuestDepartment.departmentId
        ? { ...dpt, ...newPositionGuestDepartment }
        : dpt
    );
    dispatch(
      updateNewPositionGuestBoardsDepartments(newGuestDepartmentsPositions)
    );
  } catch (error) {
    console.log(error);
  }
};

const moveGuestBoardAction = (dropResult) => (dispatch, getState) => {
  try {
    const { newIndex, oldIndex, boardId, fromDepartmentId } = dropResult;
    const isMoveForward = newIndex - oldIndex >= 0;
    const guestBoardsPositions = getSortGuestBoardPositions(
      getState(),
      fromDepartmentId
    );
    let newPosition =
      guestBoardsPositions.find((board) => board.boardId === boardId)
        .posIndex || 0;
    const leftBoard = isMoveForward
      ? guestBoardsPositions[newIndex]
      : guestBoardsPositions[newIndex - 1];
    const rightBoard = isMoveForward
      ? guestBoardsPositions[newIndex + 1]
      : guestBoardsPositions[newIndex];

    if (leftBoard && rightBoard) {
      newPosition = (leftBoard.posIndex + rightBoard.posIndex) / 2;
    } else {
      if (isMoveForward) {
        if (!rightBoard) {
          newPosition = leftBoard.posIndex + 1;
        }
      } else {
        if (!leftBoard) {
          newPosition = rightBoard.posIndex - 1;
        }
      }
    }
    const guestDepartmentsPositions = getSortGuestDepartmentsPositions(
      getState()
    );
    const newGuestDepartmentsPositions = guestDepartmentsPositions.map((dpt) =>
      dpt.departmentId === fromDepartmentId
        ? {
            ...dpt,
            boards: dpt.boards.map((board) =>
              board.boardId === boardId
                ? { ...board, posIndex: newPosition }
                : board
            ),
          }
        : dpt
    );

    dispatch(
      updateNewPositionGuestBoardsDepartments(newGuestDepartmentsPositions)
    );
  } catch (error) {
    console.log(error);
  }
};

const updateNewPositionGuestBoardsDepartments = (
  newGuestDepartmentsPositions
) => ({
  type: Types.UPDATE_GUEST_BOARD_DEPARTMENT_POSITION,
  payload: {
    newGuestDepartmentsPositions: newGuestDepartmentsPositions,
  },
});

const archivedPositionBoardSuccess = (board) => ({
  type: Types.ARCHIVE_POSITION_BOARD_SUCCESS,
  payload: { board: board },
});

const archivedPositionDepartmentSuccess = (department) => ({
  type: Types.ARCHIVE_POSITION_DEPARTMENT_SUCCESS,
  payload: { department: department },
});
export {
  setInitDepartmentPositions,
  addDepartmentPositionPending,
  addDepartmentPositionSuccess,
  setInitBoardsPositionToDepartment,
  addBoardPositionToDepartmentPending,
  addBoardPositionToDepartmentSuccess,
  moveDepartmentAction,
  moveBoardAction,
  setInitGuestBoardDepartmentPositions,
  moveGuestDepartmentAction,
  moveGuestBoardAction,
  archivedPositionBoardSuccess,
  archivedPositionDepartmentSuccess,
};
