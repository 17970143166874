export const countBoardFiltersSelector = (state) => {
  let count = 0;

  const boardId = state.boards.boardActive._id;
  const { labels, members, epics, ticketTitle } =
    state.boards.filter[boardId] || {};

  if (ticketTitle) {
    count += 1;
  }

  if (labels) {
    if (labels.selectedLabel?.length > 0 || labels.isNoLabel) {
      count += 1;
    }
  }

  if (members) {
    if (members.selectedMember?.length > 0 || members.isNoMember) {
      count += 1;
    }
  }

  if (epics) {
    if (epics.selectedEpic?.length > 0 || epics.isNoEpic) {
      count += 1;
    }
  }
  return count;
};
export const boardViewOnlySelector = (state) =>
  state.users.isAdmin ? false : state.boards.boardActive.viewOnly;

export const getAllUserInBoard = (state) => {
  const allUser = state.boards?.boardActiveInvitedMembers
    .map((user) => user)
    .concat(
      state.departments?.departmentsUsers[state.boards?.boardActive?.department]
    );
  return allUser || [];
};
