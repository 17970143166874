const Types = {
  GET_COLUMN_PENDING: "GET_COLUMN_PENDING ",
  GET_COLUMN_SUCCESS: "GET_COLUMN_SUCCESS",
  GET_COLUMN_ERROR: "GET_COLUMN_ERROR ",
  ADD_COLUMNS_SUCCESS: "ADD_COLUMN_SUCCESS",
  DELETE_COLUMNS: "DELETE_COLUMNS",
  EDIT_TITLE_COLUMN: "EDIT_TITLE_COLUMN",
  UPDATE_COLUMN_SUCCESS: "UPDATE_COLUMN_SUCCESS",
  ARCHIVE_STATE_SUCCESS: "ARCHIVE_STATE_SUCCESS",
  DONE_STATE_SUCCESS: "DONE_STATE_SUCCESS",
  ARCHIVE_STATE_EVENT_SUCCESS: "ARCHIVE_STATE_EVENT_SUCCESS",
  SET_STATE_ARCHIVE_NULL: "SET_STATE_ARCHIVE_NULL",
};

export default Types;
