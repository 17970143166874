const Types = {
  GET_TICKET_SUCCESS: "GET_TICKET_SUCCESS",
  GET_TICKET_PENDING: "GET_TICKET_PENDING",
  GET_TICKET_ERROR: "GET_TICKET_ERROR",
  ADD_TICKET_SUCCESS: "ADD_TICKET_SUCCESS",
  DELETE_TICKET_SUCCESS: "DELETE_TICKET_SUCCESS",

  GET_TICKET_DETAIL_SUCCESS: "GET_TICKET_DETAIL_SUCCESS",

  GET_USER_IN_TICKET_SUCCESS: "GET_USER_IN_TICKET_SUCCESS",
  ADD_USERS_TO_TICKET_PENDING: "ADD_USERS_TO_TICKET_PENDING",
  ADD_USERS_TO_TICKET_SUCCESS: "ADD_USERS_TO_TICKET_SUCCESS",
  DELETE_USERS_FROM_TICKET_SUCCESS: "DELETE_USERS_FROM_TICKET_SUCCESS",
  GET_TICKET_BY_CURRENT_USER_SUCCESS: "GET_TICKET_BY_CURRENT_USER_SUCCESS",
  UPDATE_TICKET_SUCCESS: "UPDATE_TICKET_SUCCESS",
  UPDATE_TICKET_MOVED_SUCCESS: "UPDATE_TICKET_MOVED_SUCCESS",
  UPDATE_DETAIL_TICKET_SUCCESS: "UPDATE_DETAIL_TICKET_SUCCESS",
  ARCHIVE_TICKET_SUCCESS: "ARCHIVE_TICKET_SUCCESS",
  ARCHIVE_TICKET_EVENT_SUCCESS: "ARCHIVE_TICKET_EVENT_SUCCESS",

  GET_ALL_TICKETS_BY_BOARD: "GET_ALL_TICKETS_BY_BOARD",
  GET_ALL_USERS_BY_TICKET_SUCCESS: "GET_ALL_USERS_BY_TICKET_SUCCESS",

  CLEAR_TICKET_CURRENT: "CLEAR_TICKET_CURRENT",
};
export default Types;
