const Types = {
    GET_BOARD_ACTIVITIES_PENDING: "GET_BOARD_ACTIVITIES_PENDING ",
    GET_BOARD_ACTIVITIES_SUCCESS: "GET_BOARD_ACTIVITIES_SUCCESS",
    GET_BOARD_ACTIVITIES_ERROR: "GET_BOARD_ACTIVITIES_ERROR ",
    GET_TICKET_ACTIVITIES_PENDING: "GET_TICKET_ACTIVITIES_PENDING ",
    GET_TICKET_ACTIVITIES_SUCCESS: "GET_TICKET_ACTIVITIES_SUCCESS",
    GET_TICKET_ACTIVITIES_ERROR: "GET_TICKET_ACTIVITIES_ERROR ",

}

export default Types